import React, { FunctionComponent, useState, useRef, useEffect, useCallback } from 'react';

import usePDFForm from 'components/usePDFForm';

import DownloadFilledPDF from 'views/DownloadFilledPDF';

const HomeContainer: FunctionComponent = () => {
  const currentStep = 'form';
  const [ pdfData, setPdfData ] = useState<string>('');
  const [ userName, setUserName] = useState<string>('');
  
  const domContainerRef = useRef(null);
  const { generatePdfOnDemand, getName } = usePDFForm({ domContainerRef, type: currentStep });

  const post = useCallback (async () => {
    const name = getName();
    const pdf = await generatePdfOnDemand();
    /* eslint-disable-next-line no-extra-boolean-cast */
    if (!!pdf) {
      setPdfData(pdf);
      setUserName(name);
    }
  }, [getName, generatePdfOnDemand ]);

  useEffect(() => {
    Promise.resolve(post())
  }, [post, pdfData, userName]);

  const pdfViewer = <div className="pdf-container" ref={domContainerRef} style={{width: '100%', height: 'auto', zIndex: -1, backgroundColor: 'white'}} />

  switch (currentStep) {
    case 'form':
      return (
        <DownloadFilledPDF pdfBase64={pdfData} userName={userName} >
          {pdfViewer}
        </DownloadFilledPDF>
      );
  
    default:
      return (
        <DownloadFilledPDF pdfBase64={pdfData} userName={userName} >
          {pdfViewer}
        </DownloadFilledPDF>
      );
  }
};

export default HomeContainer;
