import React, { FunctionComponent } from 'react';

import {  
  HomeContainer,  
  TopbarBackground,
  Wrapper,
  TopbarContent,
  WellnetLogo,
  Title,
  Text,
  ChildrenWrapper,
  DownloadButton,
  ButtonContainer,
  HeroPicture,
  HeroTitle,
  List,
  ListItem,
} from './styles';

import { IDownloadFilledPDFProps } from './types';

import logo from '../../assets/logo-wellnet-healthcare.svg';
import wavePng from '../../assets/wave.png';
import waveAvif from '../../assets/wave.avif';

const DownloadFilledPDF: FunctionComponent<IDownloadFilledPDFProps> = ( { children, pdfBase64, userName }: IDownloadFilledPDFProps ) => {
  return (
    <HomeContainer>
      <HeroPicture>
        <source
          srcSet={waveAvif}
          type="image/avif"
          media="(min-width: 460px)"
        />
        <img srcSet={wavePng} alt="" />
      </HeroPicture>
      <TopbarBackground>
        <Wrapper>
          <TopbarContent>
            <HeroTitle>
              Download Your Contact Provider Form
            </HeroTitle>
            <WellnetLogo src={logo} />
          </TopbarContent>
        </Wrapper>
      </TopbarBackground>
      <Wrapper>
        <Title>Great news!</Title> 
        <Text>
          Your company has moved the administration of your medical & pharmacy benefit plan to WellNet Healthcare. To kickstart this transition, we need your help.
        </Text>
        <List>
          <ListItem>On the form, you will provide the name of every member in your family & the contact information for their Primary Care Doctors & Specialists.</ListItem>
          <ListItem>After you complete the fillable PDF form, please download the Provider Contact Form PDF.</ListItem>
          <ListItem>Then, attach & email it to advocacy@wellnet.com.</ListItem>
          <ListItem>We’ll take it from there – providing all of the information to communicate plan changes.</ListItem>
        </List>
      </Wrapper>

      <ChildrenWrapper>
        {children}
      </ChildrenWrapper>
      <ButtonContainer>
        <DownloadButton
          title="Download Form"
          download={`${userName}_Provider_Contact_Form.pdf`}
          href={`data:application/octet-stream;base64,${pdfBase64}`}
        >
          Click to download
        </DownloadButton>
      </ButtonContainer>

    </HomeContainer>
  );
};

export default DownloadFilledPDF;
