import { createGlobalStyle } from 'styled-components';

import montserratWoff from 'fonts/montserrat.woff';
import montserratWoffSemiBold from 'fonts/Montserrat-SemiBold.woff';
import montserratWoffBold from 'fonts/Montserrat-Bold.ttf';
import montserratWoffExtraBold from 'fonts/Montserrat-ExtraBold.woff';
import monserratWoffLight from 'fonts/Montserrat-Light.woff';
import montserratBlack from 'fonts/Montserrat-Black.ttf';

import robotoBlack from 'fonts/Roboto-Black.woff';
import robotoMedium from 'fonts/Roboto-Medium.ttf';


export const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'montserrat-regular';
    src: local('montserrat'), url(${montserratWoff}) format('woff');
  }

  @font-face {
    font-family: 'montserrat-extra-bold';
    src: local('montserrat'), url(${montserratWoffExtraBold}) format('woff');
  }
  
  @font-face {
    font-family: 'montserrat-bold';
    src: local('montserrat'), url(${montserratWoffBold}) format('woff');
  }

  @font-face {
    font-family: 'montserrat-black';
    src: local('montserrat'), url(${montserratBlack}) format('woff');
  }
  
  @font-face {
    font-family: 'montserrat-semi-bold';
    src: local('montserrat'), url(${montserratWoffSemiBold}) format('woff');
  }

  @font-face {
    font-family: 'roboto-black';
    src: local('roboto-black'), url(${robotoBlack}) format('woff');
  }

  @font-face {
    font-family: 'roboto-medium';
    src: local('roboto-medium'), url(${robotoMedium}) format('woff');
  }

  @font-face {
    font-family: 'montserrat-light';
    src: local('monserrat'), url(${monserratWoffLight}) format('woff');
  }

  body {
    font-family: montserrat;
  }
`;
