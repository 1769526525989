import React, { FunctionComponent } from 'react';
import { Route, Switch, SwitchProps } from 'react-router-dom';

import HomeContainer2 from 'containers/HomeContainer2';

import DownloadBlankPDF from 'views/DownloadBlankPDF';
import DownloadFilledPDF from 'views/DownloadFilledPDF';

import { GlobalReset } from 'styles/reset.styles';
import { GlobalFonts } from 'styles/font.styles';

const Application: FunctionComponent<SwitchProps> = () => {
  return (
    <> 
      <GlobalReset/>
      <GlobalFonts/>
      <Switch>
        <Route exact path="/:company" component={HomeContainer2} />
        <Route exact path="/download-filled-pdf" component={DownloadFilledPDF} />
        <Route component={DownloadBlankPDF} />
      </Switch>
    </>
  );
};

export default Application;
