/* eslint-disable react/no-unescaped-entities */
import React, { FunctionComponent } from 'react';

import {  
  HomeContainer,  
  TopbarBackground,
  Wrapper,
  HeroTitle,
  TopbarContent,
  WellnetLogo,
  Title,
  Text,
  TextV2,
  DownloadButton,
  ButtonContainer,
  HeroPicture,
  List,
  ListItem,
} from './styles';

import logo from '../../assets/logo-wellnet-healthcare.svg';
import wavePng from '../../assets/wave.png';
import waveAvif from '../../assets/wave.avif';
import pdfFile from '../../assets/pdf/Provider_Contact_Form_Fillable.pdf';

const DownloadBlankPDF: FunctionComponent = () => {

  return (
    <HomeContainer>
      <HeroPicture>
        <source
          srcSet={waveAvif}
          type="image/avif"
          media="(min-width: 460px)"
        />
        <img srcSet={wavePng} alt="" />
      </HeroPicture>
      <TopbarBackground>
        <Wrapper>
          <TopbarContent>
            <HeroTitle>
              Download Your Contact Provider Form
            </HeroTitle>
            <WellnetLogo src={logo} />
          </TopbarContent>
        </Wrapper>
      </TopbarBackground>
      <Wrapper>
        <Title>Great news!</Title>
        <Text>
          WellNet's<TextV2> advocacy team is here to help</TextV2> explain your health benefits to all of your providers. We<TextV2> proactively reach out</TextV2> to confirm your eligibility, benefits & how to <TextV2>submit claims</TextV2> on your behalf.
        </Text>
        <List>
          <ListItem>On the form, you will provide the name for all family members enrolled under your benefits & the contact information for their Primacy Care Doctors & Specialists.</ListItem>
          <ListItem>After you complete the fillable Provider Contact Form, please download & save the PDF document.</ListItem>
          <ListItem>Lastly, attach & email it to: advocacy@wellnet.com</ListItem>
          <ListItem>We'll take it from there! You can expect a follow up from us within 5 business days of form submission.</ListItem>
        </List>
        <ButtonContainer>
          <DownloadButton
            download="Provider_Contact_Form_Fillable.pdf"
            href={pdfFile}
          >
            CLICK TO DOWNLOAD
          </DownloadButton>
        </ButtonContainer>
      </Wrapper>
    </HomeContainer>
  );
};

export default DownloadBlankPDF;
