import styled from 'styled-components';
import { 
  DESKTOP_1200,
  TABLET_1023,
  TABLET_992,
  TABLET_800,
  TABLET_768,
  TABLET_740,
  MOBILE_460,
  TABLET_600,
  MOBILE_380,
} from 'constants/sizes';

export const HomeContainer = styled.div`
  width: 100%;
  max-width: 100%;
`;

export const HeroPicture = styled.picture`
  width: 100vw;

  & > img, 
  & > source {
    width: 100vw;
    object-fit: contain;
    position: absolute;
    right: 0;
    top: -16vw;
    z-index: 1;

    @media (max-width: 2300px) {
      top: -16vw;
    }
    @media (max-width: 2100px) {
      top: -14vw;
    }
    @media (max-width: 1600px) {
      top: -11vw;
    }
    @media (max-width: ${DESKTOP_1200}px) {
      top: -9vw;
    }
    @media (max-width: ${TABLET_1023}px) {
      top: 0px;
    }
    @media (max-width: 700px) {
      object-fit: cover;
      min-height: 250px;
    }
    @media (max-width: ${TABLET_600}px) {
      min-height: 280px;
    }
    @media (max-width: ${MOBILE_460}px) {
      object-position: 90%;
      min-height: 240px;
    }
    @media (max-width: 390px) {
      min-height: 280px;
    }
    @media (max-width: ${MOBILE_380}px) {
      object-position: 80%;
      min-height: 230px;
    }
  }
`;

export const TopbarBackground = styled.div`
  width: 100%;
  text-align: center;
  min-height: 85px;
  position: relative;
  top: 20px;
  z-index: 2;
  padding: 35px 0;
  
  @media (max-width: ${TABLET_992}px) {
    top: 10px;
  }
  @media (max-width: ${TABLET_768}px) {
    padding: 20px 0;
  }
  @media (max-width: ${MOBILE_460}px) {
    top: 0px;
  }
`;

export const TopbarContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
  @media (max-width: ${TABLET_1023}px) {
    margin-top: 40px;
  }
  @media (max-width: ${TABLET_800}px) {
    margin-top: 20px;
  }
  @media (max-width: 600px) {
    margin: 0px;
    flex-direction: column-reverse;
    place-items: flex-start;
    gap: 20px;
  }
  @media (max-width: ${MOBILE_460}px) {
    gap: 15px;
  }
`;

export const HeroTitle = styled.h1`
  max-width: 460px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font: bold 38px/46px 'montserrat-bold';
  text-shadow: 0px 3px 6px #00000029;
  text-align: left;
  flex: 1;

  @media (max-width: ${TABLET_1023}px) {
    max-width: 390px;
    font: bold 32px/40px 'montserrat-bold';
  }
  @media (max-width: 700px) {
    max-width: 334px;
    font: bold 28px/36px 'montserrat-bold';
  }
  @media (max-width: ${MOBILE_460}px) {
    font: bold 26px/34px 'montserrat-bold';
    max-width: 320px;
  }
`;

export const WellnetLogo = styled.img`
  height: 67px;

  @media (max-width: ${TABLET_1023}px) {
    height: 60px;
  }
  @media (max-width: ${TABLET_800}px) {
    height: 50px;
  }
  @media (max-width: 600px) {
    height: 40px;
  }
  @media (max-width: 390px) {
    padding-bottom: 10px;
  }
`;

export const Title = styled.h2`
  width: 100%;
  margin: 250px 0 10px 0;
  color: #152E56;
  font: 600 30px/38px 'montserrat-bold';
  flex: 1;
  position: relative;
  z-index: 2;

  @media (max-width: 1064px) {
    margin: 150px 0 10px 0;
  }
  @media (max-width: 940px) {
    margin: 12vw 10px 0;
  }
  @media (max-width: ${TABLET_740}px) {
    margin: 130px 0 10px 0;
    font: 600 26px/30px 'montserrat-bold';
  }
  @media (max-width: ${TABLET_600}px) {
    margin: 100px 0 10px 0;
  }
  @media (max-width: ${MOBILE_460}px) {
    margin: 70px 0 10px 0;
    font: 600 22px/30px 'montserrat-bold';
  }
`;

export const Text = styled.p`
  width: 100%;
  color: #152E56;
  font: 600 20px/28px 'montserrat-bold';
  flex: 1;
  margin: 25px 0;
  position: relative;
  z-index: 2;

  @media (max-width: ${TABLET_740}px) {
    font: 600 19px/25px 'montserrat-bold';
  }
  @media (max-width: ${MOBILE_460}px) {
    font: 600 18px/26px 'montserrat-bold';
  }
`;

export const List = styled.ul`
  padding: 5px 0 0 20px;
  position: relative;
  z-index: 2;
`;

export const ListItem = styled.li`
  color: #152E56;
  font: 500 20px/28px 'montserrat-semi-bold';
  flex: 1;
  padding: 10px 0 10px 0;
  list-style-type: disc;
  list-style-position: outside;

  @media (max-width: ${MOBILE_460}px) {
    font: 500 16px/24px 'montserrat-bold';
  }
`;

export const ChildrenWrapper = styled.div`
  margin: 0 auto;

  @media (max-width: 1280px) {
    max-width: 90%;
    & > div > div {
      max-height: 75vw !important;
      height: 75vw !important;
    }
  }
  @media (max-width: 801px) {
    max-width: 100%;
  }
  @media (max-width: 460px) {
    & > div > div {
      max-height: 500px !important;
      height: 400px !important;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1280px) {
    max-width: 90%;
  }
`;

export const ActionContainer = styled.div`
  height: 100%;
  width: fit-content;
  margin: auto;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  padding-right:5px;
  padding-bottom:5px;
`;

export const ButtonContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  @media (max-width: ${TABLET_1023}px) {
    width: 85%;
  }
`

export const DownloadButton = styled.a`
  width: fit-content;
  margin: 50px auto 30px;
  padding: 15px 20px;
  display: flex;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 25px;
  transition: 0.2s;
  backface-visibility: hidden;
  justify-self: flex-end;
  text-decoration: none;
  background: linear-gradient(270deg, #df08e4 0%, #8d25c2 50%, #423fa3 100%);
  text-transform: uppercase;

  &:hover {
    background: linear-gradient(90deg, #df08e4 0%, #8d25c2 50%, #423fa3 100%);
    backface-visibility: hidden;
    transform: translateZ(0) scale(1.02);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 460px) {
    font-size: 20px;
  }
`;


export const CloseButton = styled.button`
  float: right;
  background: olive;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 6px;
`;

export const ModalContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ModalText = styled.p`
  text-align: center;
  margin-bottom: 2em;
`;

export const AcceptButton = styled.button`
  background: olive;
  color: white;
  border: none;
  border-radius: 3px;
  display: inline-block;
  padding: 6px;
`;
