import React, { useEffect, useState } from 'react';
import { generate } from '@pdfme/generator';
import { Template, Form, Viewer } from '@pdfme/ui';
import basePdfFile from '../../assets/pdf/Provider_Contact_Request.pdf';

const template: Template = {
  basePdf: basePdfFile,
  "schemas": [
    {
      "subscriber_name": {
        "type": "text",
        "position": {
          "x": 4.69,
          "y": 21.85
        },
        "width": 110.58,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "subscriber_phone_email": {
        "type": "text",
        "position": {
          "x": 119.73,
          "y": 21.85
        },
        "width": 110.58,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "subscriber_plan_selected": {
        "type": "text",
        "position": {
          "x": 236.29,
          "y": 21.85
        },
        "width": 110.58,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-1-fullname": {
        "type": "text",
        "position": {
          "x": 4.83,
          "y": 84.77
        },
        "width": 63.71,
        "height": 12.07,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-1-birth-date": {
        "type": "text",
        "position": {
          "x": 73.85,
          "y": 84.77
        },
        "width": 63.71,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-1-address": {
        "type": "text",
        "position": {
          "x": 142.75,
          "y": 84.77
        },
        "width": 63.71,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-1-PCP": {
        "type": "text",
        "position": {
          "x": 211.75,
          "y": 84.77
        },
        "width": 67.15,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-1-provider-phone": {
        "type": "text",
        "position": {
          "x": 283.05,
          "y": 84.77
        },
        "width": 64.77,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-2-fullname": {
        "type": "text",
        "position": {
          "x": 4.51,
          "y": 101.12
        },
        "width": 63.71,
        "height": 12.07,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-2-birth-date": {
        "type": "text",
        "position": {
          "x": 73.53,
          "y": 101.12
        },
        "width": 63.71,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-2-address": {
        "type": "text",
        "position": {
          "x": 142.43,
          "y": 101.12
        },
        "width": 63.71,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-2-PCP": {
        "type": "text",
        "position": {
          "x": 211.43,
          "y": 101.12
        },
        "width": 67.15,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-2-provider-phone": {
        "type": "text",
        "position": {
          "x": 283.08,
          "y": 101.12
        },
        "width": 64.77,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-3-fullname": {
        "type": "text",
        "position": {
          "x": 4.25,
          "y": 117.26
        },
        "width": 63.71,
        "height": 12.07,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-3-birth-date": {
        "type": "text",
        "position": {
          "x": 73.27,
          "y": 117.26
        },
        "width": 63.71,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-3-address": {
        "type": "text",
        "position": {
          "x": 142.17,
          "y": 117.26
        },
        "width": 63.71,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-3-PCP": {
        "type": "text",
        "position": {
          "x": 211.17,
          "y": 117.26
        },
        "width": 67.15,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-3-provider-phone": {
        "type": "text",
        "position": {
          "x": 283.61,
          "y": 117.26
        },
        "width": 64.77,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-4-fullname": {
        "type": "text",
        "position": {
          "x": 3.93,
          "y": 133.6
        },
        "width": 63.71,
        "height": 12.07,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-4-birth-date": {
        "type": "text",
        "position": {
          "x": 72.95,
          "y": 133.6
        },
        "width": 63.71,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-4-address": {
        "type": "text",
        "position": {
          "x": 141.85,
          "y": 133.6
        },
        "width": 63.71,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-4-PCP": {
        "type": "text",
        "position": {
          "x": 210.85,
          "y": 133.6
        },
        "width": 67.15,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-4-provider-phone": {
        "type": "text",
        "position": {
          "x": 283.61,
          "y": 133.6
        },
        "width": 64.77,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-5-fullname": {
        "type": "text",
        "position": {
          "x": 4.25,
          "y": 196.9
        },
        "width": 63.71,
        "height": 12.07,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-5-birth-date": {
        "type": "text",
        "position": {
          "x": 73.27,
          "y": 196.9
        },
        "width": 50.48,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-5-address": {
        "type": "text",
        "position": {
          "x": 129.21,
          "y": 197.16
        },
        "width": 54.71,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-5-specialist": {
        "type": "text",
        "position": {
          "x": 188.95,
          "y": 196.64
        },
        "width": 67.15,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-5-specialist-phone": {
        "type": "text",
        "position": {
          "x": 259.8,
          "y": 196.9
        },
        "width": 42.02,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-6-fullname": {
        "type": "text",
        "position": {
          "x": 3.93,
          "y": 213.25
        },
        "width": 63.71,
        "height": 12.07,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-6-birth-date": {
        "type": "text",
        "position": {
          "x": 72.95,
          "y": 213.25
        },
        "width": 50.48,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-6-address": {
        "type": "text",
        "position": {
          "x": 128.89,
          "y": 213.51
        },
        "width": 54.71,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-6-specialist": {
        "type": "text",
        "position": {
          "x": 188.62,
          "y": 212.99
        },
        "width": 67.15,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-6-specialist-phone": {
        "type": "text",
        "position": {
          "x": 259.8,
          "y": 213.25
        },
        "width": 42.02,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-7-fullname": {
        "type": "text",
        "position": {
          "x": 3.67,
          "y": 229.39
        },
        "width": 63.71,
        "height": 12.07,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-7-birth-date": {
        "type": "text",
        "position": {
          "x": 72.69,
          "y": 229.39
        },
        "width": 50.48,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-7-address": {
        "type": "text",
        "position": {
          "x": 128.63,
          "y": 229.65
        },
        "width": 54.71,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-7-specialist": {
        "type": "text",
        "position": {
          "x": 188.37,
          "y": 229.13
        },
        "width": 67.15,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-7-specialist-phone": {
        "type": "text",
        "position": {
          "x": 259.8,
          "y": 229.39
        },
        "width": 42.02,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-8-fullname": {
        "type": "text",
        "position": {
          "x": 3.35,
          "y": 245.73
        },
        "width": 63.71,
        "height": 12.07,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-8-birth-date": {
        "type": "text",
        "position": {
          "x": 72.37,
          "y": 245.73
        },
        "width": 50.48,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-8-address": {
        "type": "text",
        "position": {
          "x": 128.31,
          "y": 245.99
        },
        "width": 54.71,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-8-specialist": {
        "type": "text",
        "position": {
          "x": 188.04,
          "y": 245.47
        },
        "width": 67.15,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-8-specialist-phone": {
        "type": "text",
        "position": {
          "x": 259.8,
          "y": 245.73
        },
        "width": 42.02,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-5-specialist-type": {
        "type": "text",
        "position": {
          "x": 305.78,
          "y": 196.9
        },
        "width": 42.02,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-6-specialist-type": {
        "type": "text",
        "position": {
          "x": 305.78,
          "y": 213.25
        },
        "width": 42.02,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-7-specialist-type": {
        "type": "text",
        "position": {
          "x": 305.78,
          "y": 229.39
        },
        "width": 42.02,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      },
      "patient-8-specialist-type": {
        "type": "text",
        "position": {
          "x": 305.78,
          "y": 244.77
        },
        "width": 42.02,
        "height": 12.08,
        "fontSize": 17,
        "fontColor": "#000",
        "backgroundColor": ""
      }
    }
  ],
};

const initialInputs = {

}

const usePDFForm = ({ domContainerRef, type }: IUsePDFForm): IUsePDFFormReturn  => {

  const [ form, setForm ] = useState<Form | null>(null);
  const [ preview, setPreview ] = useState<Viewer | null>(null);
  const [ inputs, setInputs ] = useState<any>(initialInputs)

  useEffect(() => {
    if (!domContainerRef) return;
    if (type === 'form' && !form) {
      const pdfForm = new Form({domContainer: domContainerRef.current, template, inputs: [inputs] })
      pdfForm.onChangeInput(({ key, value}) => {
        setInputs(prevState => ({ ...prevState, [key]: value }))
      })
      setForm(pdfForm)
      setPreview(null)
    }
    if (type === 'viewer' && !preview) {
      setPreview(new Viewer({domContainer: domContainerRef.current, template, inputs: [inputs] }))
      setForm(null)
    }

  }, [domContainerRef, type, form, preview, inputs])

  const generatePdfOnDemand = () => {
    return generate({ template, inputs: [inputs] }).then((pdf) => {
      const base64pdf = btoa(Array(pdf.length).fill('').map((_, i) => String.fromCharCode(pdf[i])).join(''));
      return base64pdf;
    });
  }

  const getName = () => {
    const name = inputs.subscriber_name || 'empty_name';
    return name || 'empty_name'
  }

  return {
    generatePdfOnDemand,
    getName,
    form,
    preview
  }
};

export default usePDFForm;

interface IUsePDFForm {
    domContainerRef: React.MutableRefObject<any>;
    type: 'form' | 'viewer';
}

interface IUsePDFFormReturn {
  generatePdfOnDemand: () => Promise<string>;
  getName: () => string;
  form: Form | null;
  preview: Viewer | null;
}
